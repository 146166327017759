<template>
  <menu-layout value="4">
    <gaudy-title label="电子发票"></gaudy-title>
    <div class="electronic-invoice-tip">
      <div class="electronic-invoice-left">须知内容：</div>
      <div class="electronic-invoice-right">
        <p>1. 电子发票目前将在3～5个工作日内在本系统生成，请耐心等待。</p>
        <p>
          2. 财务咨询电话：朱老师 028-85531022 18048505102； 咨询时间:周一至周五
          9:00-18:00。
        </p>
      </div>
    </div>
    <el-table :data="invoiceList">
      <el-table-column type="index" label="序号" width="50" align="center" />
      <el-table-column prop="annual" label="年份" width="80" />
      <el-table-column prop="invoiceNo" label="发票编号" />
      <el-table-column prop="openTime" label="开票日期" width="120" />
      <el-table-column width="120" label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            @click="downPDFClick(scope.row)"
            type="danger"
            plain
            size="mini"
            >点击下载</el-button
          >
        </template> </el-table-column
      >>
    </el-table>
  </menu-layout>
</template>
<script>
import MenuLayout from "@/components/MenuLayout.vue";
import GaudyTitle from "@/components/gaudyTitle.vue";
import { singUpInfoInvoiceList } from "@/api/registerController";

export default {
  name: "home",
  components: { GaudyTitle, MenuLayout },
  data() {
    return {
      invoiceList: [],
    };
  },
  created() {
    this.getElectronicInvoice();
  },
  methods: {
    getElectronicInvoice() {
      singUpInfoInvoiceList().then((res) => {
        this.invoiceList = res.data.InvoiceList;
      });
    },
    downPDFClick(data) {
      if (data.invoiceUrl) {
        const link = document.createElement("a");
        const item = JSON.parse(data.invoiceUrl);
        link.href = item[0].url;
        link.download = item[0].name;
        link.click();
      } else {
        this.$message({ message: "未找到发票", type: "warning" });
      }
    },
  },
};
</script>
<style scoped lang="scss">
.electronic-invoice-tip {
  margin-top: 16px;
  background: rgba(215, 25, 23, 0.05);
  display: flex;
  padding: 10px 30px;
  align-items: flex-start;
  color: #d71917;
  font-size: 14px;
  font-weight: 500;
  .electronic-invoice-left {
    width: 70px;
    line-height: 1.8;
  }
  .electronic-invoice-right p {
    margin: 0;
    line-height: 1.8;
  }
}
</style>
